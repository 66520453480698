import React from 'react';

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <section className="section-community">
                    <div className="container">
                        <div className="row-content">
                            <div className="columns">
                                <div className="colum w-5">
                                    <div className="row">
                                        <div className="box-img">
                                            <img src="./images/mu.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-7">
                                    <div className="row res-line">
                                        <div className="title">
                                            Dive Into Our Community
                                        </div>
                                        <div className="desc">
                                            Dive into the Shark vibe on our channels! Join us on Telegram, follow the adventure on X, and get the freshest updates. Our community's ready to welcome you with open fins—let's ride the wave to riches, sharing laughs and creativity through memes and art. 
                                        </div>
                                        <div className="socical-row">
                                            <div className="item">
                                            <a href="https://t.me/SharkOnSolTelegram" target='blank'>
                                                    <img src="./images/tele.png" alt="" />
                                                </a>
                                            </div>
                                            <div className="item">
                                            <a href="https://twitter.com/SharkOnSol" target='blank'>
                                                    <img src="./images/x.png" alt="" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-logo">
                            <img src="./images/logo-shark.png" alt="" />
                        </div>
                    </div>
                </section>
            </footer>
        </>
    )
}
export default Footer