import React , {useEffect} from "react";
import { Layout } from "antd";
import Header from "../common/Header";
import Footer from "../common/Footer";
const { Content } = Layout;

function Main({ children}: any) {
    return (
        <Layout>
            <Header />
                <Content>{children}</Content>
            <Footer />
        </Layout>
    );
}

export default Main;