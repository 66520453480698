import React from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import {message} from "antd"
import "./style.scss"

const Home = () => {
    let contract = "3U8cZTbq4QkT14Kvb8Rj8CxfcyQx5V2XJHicMYE7QDmQ"
    return (
        <>
            <section className="section-banner">
                <div className="container">
                    <div className="row-content">
                        <div className="title treeLeaves">
                            <img src="./images/title-shark.png" alt="" />
                        </div>
                        <div className="desc">
                            <span className="throught">Shark isn't merely </span><span className="throught-none">a shark donning a hat;</span><span className="throught"> it represents a leap towards the future, serving as a guidepost for forward-thinkers. It embodies the essence of embracing groundbreaking innovations, breaking through traditional barriers, and heralding a new chapter in both finance and technology.
                            The future indeed is for those who welcome and integrate concepts like Shark into the mainstream.</span>
                        </div>
                        <div className="box-img">
                            <img src="https://s3-alpha-sig.figma.com/img/ef01/fd35/e57e791d537489a81f22eec98772feec?Expires=1711324800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=HMpVMphQgakb3SfIdAoiz2JLXTKDWI2loCleowdDInuV0jYrBUpxSuZzR5X2gFRtISxDAPSWVGAofhznqaAIGzIjEaGnCc7wUirquTd8SYb7mz2vpVUhHDW9m2pgRw448y082MGcaeObYEPy~RXgPRCr8ZOMCt~sHMYm1To8wWJJRkZ~ulh62WkT~CLFR545c2QRSucPc8CCmgsHr23vAl6c80HlcGITNKUjotb4vTSS9x4pvhZubTHUNo9aAMV53JB4rHVi8jsRm45szvHVG9oWHXs4KZXC8Aolg8ZiI1BWA~TW5MlRw7YUZEFW~P3cvf4I7Wp2YT3F6wCzMN9vUA__" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section id="about" className="section-about">
                <div className="container">
                    <div className="row-content">
                        <div className="title">
                            About SHARK
                        </div>
                        <div className="desc">
                            I mean bro, it’s literally a shark with hat.
                        </div>
                        <div className="box-img">
                            <img src="./images/sharkolay.png" alt="" />
                        </div>
                        <div className="title-bottom">
                            BE FR FR I'M SO SERIOUS
                        </div>
                        <div className="box-img-shark">
                            <div className="img-big">
                                <img src="./images/be.png" alt="" />
                                <div className="img-small">
                                    <img src="https://s3-alpha-sig.figma.com/img/f059/7e03/044ea12a208a37d8d394500247e8db89?Expires=1711324800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=WgYJfgNio5LR~yGrOZ4bhnuHg~j5UdQSXBNDs4nwNQMukteNpi3WVDrwNN7Rr84gC8ejtRolMuqqo8tw4WeYJJbWwB~gFVTdJRLse6yK5gjhsUkNxjQsA8HOh9k~kUSrYPsw2vN~9ovNvWxn2uENjns3JdcHiWU5J7mGr4dKLcLVZARkb~B4XZoZf2B0Xtoa~Gk8DLVIagAYHH5V0MhwcIb5Wqz1ZkJc7V4IHS0j2VhzTOCuQtTfGCDdYTBQJHoIfvBBDJCkBmWsdGy~HIlV~RyVnJTviItO3Lf4fjP-gsZ-2LYY7YFqArpjvHDBaHz3CgLVb~L0WxUO7pwy3JzekA__" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="shark" className="section-token">
                <div className="container">
                    <div className="row-content">
                        <div className="title">
                            Shark Tokenomics
                        </div>
                        <div className="columns">
                            <div className="colum w-6">
                                <div className="row">
                                    <div className="box-img">
                                        <img src="./images/p-1.png" alt="" />
                                        <div className="text">
                                            Taxes
                                        </div>
                                        <div className="number">
                                            0/0
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="colum w-6">
                                <div className="row">
                                    <div className="box-img">
                                        <img src="./images/p-2.png" alt="" />
                                        <div className="text">
                                            Mint & Freeze
                                        </div>
                                        <div className="number">
                                            Revoked
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="colum w-6">
                                <div className="row">
                                    <div className="box-img">
                                        <img src="./images/p-3.png" alt="" />
                                        <div className="text">
                                            Liquidity
                                        </div>
                                        <div className="number">
                                            Burned
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="colum w-6">
                                <div className="row">
                                    <div className="box-img">
                                        <img src="./images/p-4.png" alt="" />
                                        <div className="text">
                                            Total Supply
                                        </div>
                                        <div className="number">
                                            1,000,000,000
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row-contract">
                            <div className="contract-name">
                            {contract?.slice(0, 4)}...{contract?.slice(-4)}
                                <CopyToClipboard
                                    text={`${contract}`}
                                    onCopy={() => {
                                        message.success({
                                            type: "success",
                                            content: `Copied`,
                                            className: "custom-class",
                                            duration: 2,
                                        });
                                    }}
                                >
                                    <span className="copy-text">Copy</span>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="img-shark">
                    <img src="./images/shark-token.png" alt="" />
                </div>
            </section>
            <section id="bought" className="section-bought">
                <div className="banner-bottom">
                    <img src="./images/Isolation.png" alt="" />
                </div>
                <div className="container">
                    <div className="row-content">
                        <div className="title">
                            HOW TO BUY
                        </div>
                        <div className="item">
                            <div className="icon">
                                <img src="./images/buy-1.png" alt="" />
                            </div>
                            <div className="text-row">
                                <div className="number">
                                    01
                                </div>
                                <div className="text">
                                    Download Phantom Extension
                                </div>
                                <div className="desc">
                                    Download and install phantom on the appstore, or, alternatively if you’re on desktop download and install the browser extension
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <img src="./images/buy-2.png" alt="" />
                            </div>
                            <div className="text-row">
                                <div className="number">
                                    02
                                </div>
                                <div className="text">
                                    Secure your SOL
                                </div>
                                <div className="desc">
                                    Now all you’ve got to do is buy your SOL in the Phantom App, or, alternatively you can use an exchange and deposit using your wallet address
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <img src="./images/buy-3.png" alt="" />
                            </div>
                            <div className="text-row">
                                <div className="number">
                                    03
                                </div>
                                <div className="text">
                                    Buy some $SHARK
                                </div>
                                <div className="desc">
                                    Now go to Raydium and paste the $SHARK contract address to swap your SOL
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <img src="./images/buy-4.png" alt="" />
                            </div>
                            <div className="text-row">
                                <div className="number">
                                    04
                                </div>
                                <div className="text">
                                    Add to your Wallet
                                </div>
                                <div className="desc">
                                Now you’re all set! Welcome aboard the next rocket to the moon, just import the contract address to view you’re holdings and you can track your earnings.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Home