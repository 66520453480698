import React from 'react';
import "../style.scss"

const Header = () => {
    return (
        <>
            <header className="header">
                <div className="container">
                    <div className="main-header">
                        <div className="left">
                            <div className="box-logo">
                                <img src="./images/logo-shark.png" alt="" />
                            </div>
                        </div>
                        <div className="center">
                            <div className="main-menu">
                                <ul className="list-menu">
                                    <li>
                                        <a href="#about">About Shark</a>
                                    </li>
                                    <li>
                                        <a href="https://raydium.io/swap/?outputCurrency=3U8cZTbq4QkT14Kvb8Rj8CxfcyQx5V2XJHicMYE7QDmQ" target='blank'>Buy $Shark</a>
                                    </li>
                                    <li>
                                        <a href="https://dexscreener.com/solana/dlq6enr4ieabqw5tc6yn2nu12dwswslgpjrbvnxcrhxh" target='blank'>Chart of Shark</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="right">
                            <div className="socical-row">
                                {/* <div className="item">
                                    <a href="#">
                                        <img src="./images/discord.png" alt="" />
                                    </a>
                                </div> */}
                                <div className="item">
                                    <a href="https://t.me/SharkOnSolTelegram" target='blank'>
                                        <img src="./images/tele.png" alt="" />
                                    </a>
                                </div>
                                <div className="item">
                                    <a href="https://twitter.com/SharkOnSol" target='blank'>
                                        <img src="./images/x.png" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
export default Header